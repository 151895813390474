<template>
	<div class="tag-group">
		<div class="flex wrap">
			<p v-for="(i, index) in list" :key="index" class="tag-item fs-12 mr-2 mb-2"
			   :class="[`check-${checkColor}`, {'check-tag' : checkValue.has(i.tagId)} ]" @click="handleCheck(i)">
				{{ i.tagName }}
			</p>
		</div>
		<span class="mt-3 fs-12 color-warning">注意：不要在微信端删除选中的标签，否则有封号风险。</span>
	</div>

</template>

<script>
export default {
	name: 'TheTagList',
	props: {
		list: {
			type: Array,
			default: () => []
		},
		modelValue: {
			type: Set,
			default: () => new Set()
		},
		checkColor: {
			type: String,
			default: 'green'
		}
	},
	data() {
		return {}
	},
	computed: {
		checkValue: {
			set(val) {
				this.$emit('update:modelValue', val)
			},
			get() {
				return this.modelValue
			}
		}
	},
	methods: {
		handleCheck(val) {
			if (this.checkValue.has(val.tagId)) {
				this.checkValue.delete(val.tagId)
			} else {
				this.checkValue.add(val.tagId)
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.tag-item {
	padding: 4px 6px;
	background: #F4F4F4;
	border-radius: 4px 4px 4px 4px;
	color: rgba(0, 0, 0, 0.7);
}

.check-green.check-tag {
	background: #E6F8EB;
	color: #129729;

}

.check-warning.check-tag {
	background: #FFECEF;
	color: #FF445E;
}
</style>
